const english = {
  errmsg: 'Something\'s wrong~',
  up: 'Parent Address:',
  bindup: 'Bind to superior',
  confirm: 'Confirm',
  toast10: 'Initiated, waiting for block confirmation',
  toast13: 'Failed to obtain address',
  total: 'Total subscription',
  total1: 'EGA balance',
  total2: 'Dynamic rewards',
  toast4: 'Sorry, your credit is running low',
  withraw: 'extract',
  team: 'Team performance',
  team1: 'team',
  team2: 'people',
  team3: 'Direct push',
  one: 'Limited to one purchase',
  onetime: 'cycle',
  oneprice: 'limit',
  day: 'day',
  buy: 'subscription',
  sheng: 'surplus',
  income: 'Expected revenue',
  title: 'Custody',
  time: 'term',
  close: 'Early closure',
  timedown: 'Countdown: ',
  daoqi: 'expire',
  buyed: 'Subscribed',
  incomed: 'Gained',
  nodata: 'There are currently no hosted POOLs',
  nodata1: 'Not yet',
  tiyan: 'Experience',
  oneNum: 'Limit subscription',
  allIncome: 'Total revenue',
  balance: 'Available assets',
  submit: 'Confirm subscription',
  poolAll: 'Total POOL',
  shengyu: 'Remaining available for purchase',
  buynum: 'Subscription amount',
  input: 'Enter amount',
  max: 'maximum',
  title1: 'TianPOOL - Withdrawal of income',
  can: 'Extractable',
  sure: 'Confirm extraction',
  title2: 'Experience POOL - Extract Revenue',
  tab1: 'In custody',
  tab2: 'Ended',
  tian: 'day',
  shi: 'hour',
  fen: 'minute',
  miao: 'second',
  alert1: 'close',
  alert2: 'Are you sure to close in advance',
  alert3: 'Please enter the address',
  alert4: 'Please enter the quantity',
  alert5: 'Insufficient balance',
  alert6: 'Minimum investment amount',
  end: 'End',
  tabbar1: 'Management',
  tabbar2: 'Ecology',
  tabbar3: 'Investment',
  noopen: 'Not yet open. Stay tuned',
  ketiqu: 'Extractable',
  jingtai: 'Static',
  jiedian: 'Node',
  futou: 'Confirm re investment',
  shifang: 'Released',
  confirm1: 'Open',
  confirm2: 'Are you sure to restart',
  cancel: 'Cancel',
  copyLink: 'Copy invitation link',
  copySuccess: 'Successfully copied',
  reOpen: 'Reopen',
  everyDay: 'Daily limit',
  todayNum: 'Today limit',
  minnum: 'Minimum extraction',
  exchange1: 'Exchange',
  exchange2: 'Send (Balance:',
  exchange3: 'Receiving (estimated)',
  add: 'Principal',
  add1: 'Income reinvestment',
  add2: 'Principal reinvestment',
  add3: 'Daily return rate:',
  add4: 'Undrawn principal:',
  add5: 'Min 0.1BNB and multiples of 0.1',
  add6: 'Total destruction',
  add7: 'Total output revenue',
  add8: 'My subscription',
  add9: 'Direct push effective',
  add10: 'Principal release in progress',
  add11: 'Principal released:',
  add12: 'Withdrawable principal:',
  add13: 'Current rate of return',
  add14: 'Subscription unit price',
  add15: 'subscription price',
  add16: 'Total be excavated',
  add17: 'Extracted',
  add18: 'Daily release time',
  add19: 'My mining pool',
  add20: 'Subscription records',
  nft1: 'store',
  nft2: 'knapsack',
  nft3: 'Buy Now',
  nft4: 'Transfer out',
  nft5: 'Transfer address',
  nft6: 'Transfer out quantity',
  newBuy1: 'Rich Warehouse',
  newBuy2: 'Current Coin Quantity:',
  newBuy3: 'Next release time:',
  newBuy4: 'Next release quantity:',
  newBuy5: 'Add warehouse',
  newBuy6: 'Quantity of additional warehouses',
  // pool page
  poolPageIncome1: 'Market revenue',
  poolPageIncome2: 'Team benefits',
  poolPageBuy1: 'Purchase from the market',
  poolPageBuy2: 'Minimum purchase',
  poolPageBuy3: 'Maximum purchase',
  poolPagePool1: 'Subscription:',
  poolPagePool2: 'Issued',
  poolPagePool3: 'Generated income',
  poolPagePool4: 'Subscription time',
  poolPagePool5: 'Redeem',
}
export default english;
