const thai = {
  errmsg: 'มีปัญหาเกิดขึ้นแล้ว',
  up: 'ที่อยู่ผู้บังคับบัญชา:',
  bindup: 'ผูกผู้บังคับบัญชา',
  confirm: 'ยืนยัน',
  toast10: 'เปิดตัวรอการยืนยันบล็อก',
  toast13: 'การรับที่อยู่ล้มเหลว',
  total: 'สมัครสมาชิกทั้งหมด',
  total1: 'ยอดคงเหลือ EGA',
  total2: 'รางวัลแบบไดนามิก',
  toast4: 'ยอดคงเหลือไม่เพียงพอ',
  withraw: 'การสกัด',
  team: 'ประสิทธิภาพของทีม',
  team1: 'ทีม',
  team2: 'คน',
  team3: 'กดตรง',
  one: 'ครั้งเดียว',
  onetime: 'ระยะเวลา',
  oneprice: 'วงเงิน',
  day: 'วัน',
  buy: 'สมัครสมาชิก',
  sheng: 'ส่วนที่เหลือ',
  income: 'ผลประโยชน์ที่คาดหวัง',
  title: 'โฮสติ้ง',
  time: 'ระยะเวลา',
  close: 'ปิดก่อนกำหนด',
  timedown: 'นับถอยหลังสู่การขุดเหมือง',
  daoqi: 'หมดอายุ',
  buyed: 'สมัครสมาชิกแล้ว',
  incomed: 'รายได้',
  nodata: 'POOL ที่ยังไม่ได้โฮสต์ในขณะนี้',
  nodata1: 'ยังไม่ใช่ตอนนี้',
  tiyan: 'ประสบการณ์',
  oneNum: 'จำกัด การสมัครสมาชิก',
  allIncome: 'รายได้รวม',
  balance: 'สินทรัพย์ที่มีอยู่',
  submit: 'ยืนยันการสมัครสมาชิก',
  poolAll: 'POOL ทั้งหมด',
  shengyu: 'เหลือให้ซื้อ',
  buynum: 'จำนวนสมาชิก',
  input: 'ใส่จำนวนเงิน',
  max: 'แม็กซ์',
  title1: 'TianPOOL - ถอนเงิน',
  can: 'สกัดได้',
  sure: 'ยืนยันการถอนเงิน',
  title2: 'ประสบการณ์ POOL - ถอนกำไร',
  tab1: 'ในโฮสติ้ง',
  tab2: 'จบแล้ว',
  tian: 'วัน',
  shi: 'ชั่วโมง',
  fen: 'นาที',
  miao: 'วินาที',
  alert1: 'ปิด',
  alert2: 'ยืนยันจะปิดก่อนกำหนด',
  alert3: 'กรุณากรอกที่อยู่',
  alert4: 'กรุณาใส่ปริมาณ',
  alert5: 'ยอดคงเหลือไม่เพียงพอ',
  alert6: 'จำนวนเงินลงทุนขั้นต่ำ',
  end: 'สิ้นสุด',
  tabbar1: 'สินทรัพย์',
  tabbar2: 'นิเวศวิทยา',
  tabbar3: 'จิ๋ว',
  noopen: 'ยังไม่เปิดให้บริการในขณะนี้ โปรดติดตาม',
  ketiqu: 'กำไรที่ถอนได้',
  jingtai: 'กำไรคงที่',
  jiedian: 'กำไรโหนด',
  futou: 'ยืนยันการลงคะแนนซ้ำ',
  shifang: 'ปล่อยแล้ว',
  confirm1: 'เปิด',
  confirm2: 'ยืนยันจะเปิดให้บริการอีกครั้ง',
  cancel: 'การยกเลิก',
  copyLink: 'คัดลอกลิงก์เชิญ',
  copySuccess: 'คัดลอกสำเร็จ',
  reOpen: 'เปิดอีกครั้ง',
  everyDay: 'วงเงินถอนต่อวัน',
  todayNum: 'วงเงินที่เหลือในวันนี้',
  minnum: 'ถอนขั้นต่ำ',
  exchange1: 'แลก',
  exchange2: 'ส่ง (ยอดคงเหลือ:',
  exchange3: 'การรับ (โดยประมาณ) ',
  add: 'เงินต้น',
  add1: 'การคืนกำไร',
  add2: 'เงินต้นคืน',
  add3: 'อัตราผลตอบแทนรายวัน:',
  add4: 'เงินต้นที่ยังไม่ถอน:',
  add5: 'ต่ำสุด 0.1BNB และทวีคูณของ 0.1',
  add6: 'ทำลายทั้งหมด',
  add7: 'ผลผลิตรวม',
  add8: 'การสมัครของฉัน ',
  add9: 'กดตรงมีประสิทธิภาพ ',
  add10: 'ในการปล่อยเงินต้น ',
  add11: 'เงินต้นได้รับการปล่อยตัว:',
  add12: 'เงินต้นที่สามารถถอนได้:',
  add13: 'อัตราผลตอบแทนปัจจุบัน',
  add14: 'ราคาต่อหน่วย',
  add15: 'ราคาสมัคร',
  add16: 'จำนวนที่จะขุด',
  add17: 'ถอนแล้ว',
  add18: 'ปล่อยเวลาทุกวัน',
  add19: 'สระว่ายน้ำเหมืองของฉัน',
  add20: 'บันทึกการสมัครสมาชิก',
  nft1: 'ร้านค้า',
  nft2: 'กระเป๋าเป้สะพายหลัง',
  nft3: 'ซื้อตอนนี้',
  nft4: 'โอนออก',
  nft5: 'โอนย้ายที่อยู่',
  nft6: 'จำนวนโอนออก',
  newBuy1: 'โกดังรวย',
  newBuy2: 'จำนวนเหรียญปัจจุบัน:',
  newBuy3: 'เวลาเปิดตัวครั้งต่อไป:',
  newBuy4: 'จำนวนปล่อยครั้งต่อไป:',
  newBuy5: 'เพิ่มโพซิชั่น',
  newBuy6: 'จำนวนโพซิชั่นที่เพิ่มขึ้น',
  // pool page
  poolPageIncome1: 'กำไรเชิงพาณิชย์',
  poolPageIncome2: 'รายได้ของทีม',
  poolPageBuy1: 'ซื้อ Marketmaker',
  poolPageBuy2: 'การซื้อขั้นต่ำ',
  poolPageBuy3: 'การซื้อสูงสุด',
  poolPagePool1: 'สมัครสมาชิก:',
  poolPagePool2: 'ออก',
  poolPagePool3: 'ผลผลิต',
  poolPagePool4: 'ระยะเวลาการสมัครสมาชิก',
  poolPagePool5: 'แลก',
}
export default thai;
