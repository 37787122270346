const chinese1 = {
  errmsg: '出現問題啦～',
  up: '上級地址：',
  bindup: '綁定上級',
  confirm: '確認',
  toast10: '已發起，等待區塊確認',
  toast13: '獲取地址失敗',
  total: '總認購',
  total1: 'EGA餘額',
  total2: '動態獎勵',
  toast4: '餘額不足',
  withraw: '提取',
  team: '團隊業績',
  team1: '團隊',
  team2: '人',
  team3: '直推',
  one: '僅限購一次',
  onetime: '週期',
  oneprice: '限額',
  day: '天',
  buy: '認購',
  sheng: '剩餘',
  income: '預計收益',
  title: '託管',
  time: '期限',
  close: '提前關閉',
  timedown: '挖礦倒數計時：',
  daoqi: '到期',
  buyed: '已認購',
  incomed: '已收益',
  nodata: '暫時還沒有託管的POOL',
  nodata1: '暫時還沒有',
  tiyan: '體驗',
  oneNum: '限額認購',
  allIncome: '總收益',
  balance: '可用資產',
  submit: '確認認購',
  poolAll: 'POOL總量',
  shengyu: '剩餘可購',
  buynum: '認購金額',
  input: '輸入金額',
  max: '最大',
  title1: '天POOL-選取收益',
  can: '可選取',
  sure: '確認選取',
  title2: '體驗POOL-選取收益',
  tab1: '託管中',
  tab2: '已結束',
  tian: '天',
  shi: '時',
  fen: '分',
  miao: '秒',
  alert1: '關閉',
  alert2: '確認提前關閉嗎',
  alert3: '請輸入地址',
  alert4: '請輸入數量',
  alert5: '餘額不足',
  alert6: '最低投資金額',
  end: '結束',
  tabbar1: '資產包理財',
  tabbar2: '生態',
  tabbar3: '智投',
  noopen: '暫未開放敬請期待',
  ketiqu: '可選取收益',
  jingtai: '靜態收益',
  jiedian: '節點收益',
  futou: '確認複投',
  shifang: '已釋放',
  confirm1: '開啟',
  confirm2: '確認重新開啟嗎',
  cancel: '取消',
  copyLink: '複製邀請連結',
  copySuccess: '複製成功',
  reOpen: '重新開啟',
  everyDay: '每日選取額度',
  todayNum: '今日剩餘額度',
  minnum: '最低選取',
  exchange1: '兌換',
  exchange2: '發送（餘額：',
  exchange3: '接收（預估）',
  add: '本金',
  add1: '收益複投',
  add2: '本金複投',
  add3: '每日收益率：',
  add4: '未選取本金：',
  add5: '最小0.1BNB且為0.1的倍數',
  add6: '總銷毀',
  add7: '總產出收益',
  add8: '我的認購',
  add9: '直推有效',
  add10: '本金釋放中',
  add11: '本金已釋放：',
  add12: '可選取本金：',
  add13: '當前收益率',
  add14: '認購單價',
  add15: '認購價格',
  add16: '待挖總量',
  add17: '已選取',
  add18: '每天釋放時間',
  add19: '我的礦池',
  add20: '認購記錄',
  nft1: '商店',
  nft2: '背包',
  nft3: '立即購買',
  nft4: '轉出',
  nft5: '轉出地址',
  nft6: '轉出數量',
  newBuy1: '暴富倉',
  newBuy2: '當前幣量：',
  newBuy3: '下次釋放時間：',
  newBuy4: '下次釋放數量：',
  newBuy5: '加倉',
  newBuy6: '加倉數量',
  // pool page
  poolPageIncome1: '市商收益',
  poolPageIncome2: '團隊收益',
  poolPageBuy1: '購買市商',
  poolPageBuy2: '最小購買',
  poolPageBuy3: '最大購買',
  poolPagePool1: '認購：',
  poolPagePool2: '已發放',
  poolPagePool3: '已產出收益',
  poolPagePool4: '認購時間',
  poolPagePool5: '贖回',
}
export default chinese1;
